/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ApiInsuranceMapDto,
  ApiInsuranceMapDtoListResponseModel,
  ApiInsuranceMapDtoModelResponse,
    ApiInsuranceMapGroupedDtoListResponseModel,
  ColumnFilter,
  GeneralStatus,
  SortType,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ApiInsuranceMaps<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ApiInsuranceMaps
   * @name LoadList
   * @request GET:/ApiInsuranceMaps/Load
   */
  loadList = (
    query?: {
      /** @format int64 */
      InsuranceId?: number;
      /** @format int32 */
      Take?: number;
      /** @format int32 */
      Skip?: number;
      IncludeIds?: number[];
      "OrderBy.Field"?: string;
      "OrderBy.Type"?: SortType;
      SearchTerm?: string;
      ColumnFilters?: ColumnFilter[];
      Status?: GeneralStatus;
      /** @format int64 */
      PracticeId?: number;
      ForDropdown?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiInsuranceMapDtoListResponseModel, any>({
      path: `/ApiInsuranceMaps/Load`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
    /**
     * No description
     *
     * @tags ApiInsuranceMaps
     * @name LoadGroupedList
     * @request GET:/ApiInsuranceMaps/LoadGrouped
     */
    loadGroupedList = (
        query?: {
            /** @format int64 */
            InsuranceId?: number;
            /** @format int32 */
            Take?: number;
            /** @format int32 */
            Skip?: number;
            IncludeIds?: number[];
            "OrderBy.Field"?: string;
            "OrderBy.Type"?: SortType;
            SearchTerm?: string;
            ColumnFilters?: ColumnFilter[];
            Status?: GeneralStatus;
            /** @format int64 */
            PracticeId?: number;
            ForDropdown?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<ApiInsuranceMapGroupedDtoListResponseModel, any>({
            path: `/ApiInsuranceMaps/LoadGrouped`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
  /**
   * No description
   *
   * @tags ApiInsuranceMaps
   * @name PostApiInsuranceMaps
   * @request POST:/ApiInsuranceMaps/Add
   */
  postApiInsuranceMaps = (data: ApiInsuranceMapDto, params: RequestParams = {}) =>
    this.request<ApiInsuranceMapDtoModelResponse, any>({
      path: `/ApiInsuranceMaps/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ApiInsuranceMaps
   * @name GetByIdList
   * @request GET:/ApiInsuranceMaps/GetById
   */
  getByIdList = (
    query?: {
      /** @format int64 */
      id?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiInsuranceMapDtoModelResponse, any>({
      path: `/ApiInsuranceMaps/GetById`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ApiInsuranceMaps
   * @name DeleteDelete
   * @request DELETE:/ApiInsuranceMaps/Delete
   */
  deleteDelete = (
    query?: {
      /** @format int64 */
      id?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiInsuranceMapDtoModelResponse, any>({
      path: `/ApiInsuranceMaps/Delete`,
      method: "DELETE",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ApiInsuranceMaps
   * @name UpdateCreate
   * @request POST:/ApiInsuranceMaps/Update
   */
  updateCreate = (data: ApiInsuranceMapDto, params: RequestParams = {}) =>
    this.request<ApiInsuranceMapDtoModelResponse, any>({
      path: `/ApiInsuranceMaps/Update`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
